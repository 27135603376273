import {useEffect} from "react";
import {useLocation} from "react-router-dom";

const AuthRoute = ({children, auth, name}: any) => {
    const location = useLocation()

    useEffect(() => {
        document.title = name
    }, [location.pathname]);

    return children;
};
export default AuthRoute;
