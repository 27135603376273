import {
    Route,
    Routes,
} from "react-router-dom";
import route ,{ RouteConfig } from "./route";
import AuthRoute from "./route/authRouter";

const App = () => {
    // 处理routers
    const RouteAuthFun = (
        (routeList: RouteConfig[]) => {
            return routeList.map(
                (item:RouteConfig) => {
                    return (
                        <Route
                            path={item.path}
                            element={
                                <AuthRoute
                                    auth={item.auth}
                                    key={item.path}
                                    name={item.name}
                                >
                                    {item.element}
                                </AuthRoute>
                            }
                            key={item.path}
                        >
                            {/* 递归调用，因为可能存在多级的路由 */}
                            {item?.children && RouteAuthFun(item.children)}
                        </Route>
                    );
                }
            );
        }
    );
    return <Routes>{RouteAuthFun(route)}</Routes>;
};
export default App;
