import React, { lazy } from "react";

import { Navigate } from "react-router-dom";

const Layout = lazy(() => import("@/pages/Layout"));
const My = lazy(() => import("@/pages/My"));
const Home = lazy(() => import("@/pages/Home"));
const Vip = lazy(() => import("@/pages/Vip"));

export interface RouteConfig {
  path: string;
  element: React.ReactNode;
  auth?: boolean;
  children?: RouteConfig[];
  redirect?:string;
  name:string
}


const route:RouteConfig[] = [
  {
    path: "/",
    element: <Navigate to="/home"></Navigate>,
    name:'金晗订阅',
  },
  {
    path: "/home",
    element: <Layout></Layout>,
    name:'金晗订阅',
    children: [
      {
        path: "/home",
        element: <Navigate to="/home/index"></Navigate>,
        name:'金晗订阅',
      },
      {
        path: "/home/index",
        element: <Home></Home>,
        name:'金晗订阅',
      },
      {
        path: "/home/vip",
        element: <Vip></Vip>,
        name:'金晗订阅(vip套餐)',
      },
      {
        path: "/home/my",
        element: <My></My>,
        name:'金晗订阅',
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/home"></Navigate>,
    name:'金晗订阅',
  },
];

export default route;
